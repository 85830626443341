import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import React from "react";

const PlayPage: React.FC = () => {
  useRedirectHandler(() => {
    return "https://www.classdojo.com/play-dojo-islands";
  });
  return (
    <>
      <SEO noindex={true} />
    </>
  );
};

export default PlayPage;
